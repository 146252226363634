/*
  middleware odpowiadający za pozyskanie access tokenu w przypadku gdy refreshToken jest dostępny, ale accessToken wygasł
  dodatkowo czyści kontekst po wejściu na strone logowania w przypadku gdy nie ma refresh tokena
*/

import { useContextStore } from '~coreStores/context.store';
import { useUserAuthStore } from '~coreStores/user-auth.store';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const runtimeConfig = useRuntimeConfig();

  const { $routeNames } = useNuxtApp();

  const userAuthStore = useUserAuthStore();
  const { getBailiffOfficeUserData } = storeToRefs(userAuthStore);
  const { accessToken, refreshToken } = userAuthStore.getAuthTokens();

  if (getBailiffOfficeUserData.value) {
    // jeśli użytkownik ma tylko jeden kontekst
    if (getBailiffOfficeUserData.value?.contexts.length === 1) {
      const contextStore = useContextStore();
      contextStore.changeContext(getBailiffOfficeUserData.value.contexts[0], false);

      // @TODO usunąć warunek poniżej i przekierowanie do strony głównej po opublikowaniu OK na PROD
      if (runtimeConfig.public.publishedOK) {
        return navigateTo({
          name: $routeNames.bailiff.auctions.index,
          query: from.query
        });
      }

      return navigateTo({
        name: $routeNames.index,
        query: from.query
      });
    }

    return navigateTo({ name: $routeNames.bailiff.context, query: from.query });
  }

  if (refreshToken && !getBailiffOfficeUserData.value) {
    if (!accessToken) {
      await userAuthStore.refreshTokenAction();
    }

    if (getBailiffOfficeUserData.value) {
      return navigateTo({ name: $routeNames.bailiff.context, query: from.query });
    }
  }
});
